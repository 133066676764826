import { FC } from 'react';
import classNames from 'classnames';

import { getFormattedCountdownDate, useCountdown } from 'hooks/useCountdown';

import { tagClasses } from './styles';

type TimerTagProps = {
  endDate: number;
  className?: string;
};

export const TimerTag: FC<TimerTagProps> = ({ endDate, className }) => {
  const countdown = useCountdown(endDate);
  const formattedCountdownDate = getFormattedCountdownDate(countdown, endDate);

  return (
    <div
      className={classNames(
        'position-absolute text-center riforma-regular fs-12px text-white border-radius-46',
        tagClasses,
        className
      )}
      style={{
        background: 'rgba(0, 0, 0, 0.4)',
        zIndex: 5,
        right: 0,
        minWidth: 80
      }}
    >
      {formattedCountdownDate}
    </div>
  );
};
