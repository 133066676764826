import { FC, ReactNode } from 'react';
import { MdBolt } from '@react-icons/all-files/md/MdBolt';
import classNames from 'classnames';

import { COLORS } from 'consts';
import { useBezelUser } from 'context/bezelUser';
import { useBranding } from 'context/branding';
import { useModal } from 'context/modal/ModalContext';
import styles from 'styles/Listing.module.scss';
import { Listing, ListingStatus, PurchaseRequestOffer } from 'types';
import { formatPrice } from 'utils/text-helpers';

import { tagClasses, tagClassesThumbnail } from './styles';
import { TimerTag } from './TimerTag';

import { ListingTagInfoModal } from '../ListingTagInfoModal';

export type ListingTagProps = {
  listing: Listing;
  className?: string;
  color?: 'gray' | 'green';
  requestOffer?: PurchaseRequestOffer;
  isAuctionThumbnail?: boolean;
  isThumbnail?: boolean;
};

export const ListingTag: FC<ListingTagProps> = ({
  requestOffer,
  listing,
  className,
  color = 'green',
  isAuctionThumbnail,
  isThumbnail
}) => {
  const { openModal, closeModal } = useModal();
  const { useSellerBranding } = useBranding();
  const isAuction = listing.activePricingModel === 'AUCTION';
  const { user } = useBezelUser();
  const isBuyer =
    !!requestOffer && !!user?.buyerProfile && user.buyerProfile.id === requestOffer.purchaseRequest.buyerProfile.id;

  // disable tags on seller sites
  if (useSellerBranding) {
    return;
  }

  let text: ReactNode = '';
  let description = '';
  let bg = '';

  if (isAuction && isAuctionThumbnail) {
    text = listing.auctionInfo?.ended ? 'Auction ended' : 'Auction';
    bg = 'rgba(0, 0, 0, 0.4)';
  } else if (isAuction) {
    text = (
      <div className='d-flex align-items-center justify-content-between'>
        <MdBolt className='me-1' size='1.4em' /> <span>Auction</span>
      </div>
    );
    bg = '#2A8370';
  } else if (listing.promotion?.type === 'TIMED' && listing.status === ListingStatus.Published) {
    const initialPrice = listing.listedPriceCents;
    const finalPrice = listing.promotion.priceCents;
    const discountValue = formatPrice(initialPrice - finalPrice);

    text = `${discountValue} OFF`;
    bg = COLORS.primary;
  } else if (requestOffer && requestOffer.status === 'AWAITING_RESPONSE' && isBuyer) {
    text = 'Only you see this price';
    bg = COLORS.primary;
  } else {
    listing.tags.forEach(tag => {
      // if both BEST_PRICE and GREAT_PRICE keys present BEST_PRICE should be displayed
      if (text === 'Best price') {
        return;
      }

      if (tag.stableKey === 'BEST_PRICE') {
        bg = 'linear-gradient(269deg, #07745B 0%, #076D74 100%)';
        text = 'Best price';
        description =
          'This watch is under the market value for this specific model and the best priced example on Bezel currently';
      } else if (tag.stableKey === 'GREAT_PRICE') {
        text = 'Great price';
        description =
          'This watch is under the market value for this specific model and considered by our team to be a great price';
        bg = color === 'gray' ? 'rgba(0,0,0,0.4)' : COLORS.primary;
      }
    });
  }

  if (!text) {
    return null;
  }

  const openInfoModel = () =>
    openModal(
      <ListingTagInfoModal title={text} closeModal={closeModal}>
        {description}
      </ListingTagInfoModal>
    );

  return (
    <>
      <div
        role='button'
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          openInfoModel();
        }}
        className={classNames(
          styles.listingTag,
          'position-absolute riforma-regular fs-12px text-white border-radius-46',
          { 'pointer-events-none': !description, [tagClassesThumbnail]: isThumbnail },
          className,
          tagClasses
        )}
        style={{
          background: bg,
          zIndex: 5
        }}
      >
        {text}
      </div>
      {!isThumbnail && listing.promotion?.timedDealExpiration && listing.status === ListingStatus.Published && (
        <TimerTag className={className} endDate={listing.promotion?.timedDealExpiration} />
      )}
    </>
  );
};
