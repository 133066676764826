import { FC, PropsWithChildren, ReactNode } from 'react';

import { Slideout, SlideoutCommonProps } from 'components/design-system/Slideout';
import { useHideIntercom } from 'hooks';
import { intercomShowNewMessage } from 'lib/intercom';

export const ListingTagInfoModalTestIds = {
  container: 'listing-tag-info-modal-container',
  button: 'listing-tag-info-modal-button'
};

export type ListingTagInfoModalProps = {
  title: ReactNode;
} & SlideoutCommonProps &
  PropsWithChildren;

export const ListingTagInfoModal: FC<ListingTagInfoModalProps> = ({ isOpen, closeModal, children, title }) => {
  useHideIntercom();

  return (
    <Slideout
      bodyClassName='d-flex flex-column'
      title={title}
      data-testid={ListingTagInfoModalTestIds.container}
      submit={{
        onClick: intercomShowNewMessage,
        testId: ListingTagInfoModalTestIds.button,
        title: 'Talk to a Concierge about it',
        className: 'fs-14px fs-lg-16px'
      }}
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <div className='flex-1 d-flex align-items-center justify-content-center text-center text-primary riforma-regular fs-16px opacity-60 px-4'>
        {children}
      </div>
    </Slideout>
  );
};
